import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { hideSpinner, showSpinner } from "../../store/spinner/spinnerActions";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
// import { auth } from "../../auth/firebaseAuth";
import firebase from "firebase/app";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { baseUrl, setCurrentUser,getCurrentUser } from "../../util/host";

//const axios = Axios.create();

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://stylishop.com/">
        Stylishop
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const [region] = React.useState(localStorage.getItem('region')?.toUpperCase() ||  "GULF");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = React.useState({
    email: "",
    password: "",
    rememberme: false,
    authenticated: false,
  });
  /**
   * Refresh idToken for earch 15 minute and update the same in localstorage.
   */
  useEffect(() => {
    // setInterval(() => {
    //   firebase
    //     .auth()
    //     .currentUser?.getIdToken(true)
    //     .then((idToken) => {
    //       Axios.defaults.headers["token"] = idToken;
    //       localStorage.setItem("idToken", idToken);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }, 15 * 60 * 1000);
    const loggedInUser = getCurrentUser();
    if (loggedInUser) {
      history.push("/mapper/country");
    } 
  }, []);

  const login = (e) => {
    e.preventDefault();
    const domain = new URL(process.env.REACT_APP_API_URL).hostname;
    dispatch(showSpinner());
    Axios.post(`${baseUrl("REACT_APP_AUTH_SERVICE_URL")}/login`, {
      email: state.email,
      password: state.password,
      domain,
    })
      .then((res) => {
        const {
          data: { data: loginUser },
        } = res;
        if (res.status) {
          const verifyOtpStatus = loginUser?.message;
          if (!verifyOtpStatus) {
            setCurrentUser(loginUser);
            Axios.defaults.headers[
              "Authorization"
            ] = `Bearer ${loginUser?.idToken}`;
            history.push("/mapper/country");
          } else {
            localStorage.setItem("email", state.email);
            console.log("message no");
            history.push("/verify-otp");
          }
        }
        // history.push("/verify-otp");
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideSpinner());
        setState({ ...state, error: "Invalid username or password" });
      });
    // auth
    //   .signInWithEmailAndPassword(state.email, state.password)
    //   .then((res) => {
    //     const idToken = res.user.ya;
    //     Axios.defaults.headers["token"] = idToken;
    //     localStorage.setItem("idToken", idToken);
    //     setState({ ...state, authenticated: true });
    //     localStorage.setItem("region", region);
    //     history.push("/mapper/country");
    //     dispatch(hideSpinner());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     dispatch(hideSpinner());
    //     setState({ ...state, error: "Invalid username or password" });
    //   });
  };

  const loginWithGoogleProvider = (e) => {
    e.preventDefault();
    dispatch(showSpinner());
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.setCustomParameters({
      hd: "stylishop.com",
    });

    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((res) => {
        if (res.user.email.includes("@stylishop.com")) {
          const idToken = res.user.ya;
          Axios.defaults.headers["Authorization"] = idToken;
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("region", region);
          setState({ ...state, authenticated: true });
          history.push("/mapper/country");
        } else {
          setState({
            ...state,
            error: "You've not logged in with stylishop domain.",
          });
        }
        dispatch(hideSpinner());
      })
      .catch((error) => {
        dispatch(hideSpinner());
        console.error("Error signing in with password and email", error);
      });
  };
  const handleChange = (event) => {
    localStorage.setItem("region", event.target.value);
    window.location.reload();

  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please Login
        </Typography>

        {state.error ? (
          <>
            <br />
            <Alert severity="error">{state.error}</Alert>{" "}
          </>
        ) : (
          ""
        )}

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            onChange={(e) => setState({ ...state, email: e.target.value })}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Region</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={region}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value="GULF">GCC</MenuItem>
              <MenuItem value="IN">INDIA</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={login}
          >
            Sign In
          </Button>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={loginWithGoogleProvider}
          >
            Sign In With Google
          </Button> */}
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
